.container {
  max-width: 38rem;
  margin: 0 auto;
}

.section {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  background-image: url('../../../images/svg/sheet.svg');
  background-size: 100% 100%;
}

.textFieldWrapper {
  align-items: stretch !important; /* overriding defaults */
}

.textField {
  margin-bottom: 0.5rem !important; /* overriding defaults */
}

.inputBtnWrapper {
  display: flex;
  align-items: center;
  border: none;
  height: unset !important; /* overriding defaults */
  max-height: unset !important; /* overriding defaults */
}

.inputBtn {
  padding: 1rem 0 !important; /* overriding defaults */
}

@media (min-width: 576px) {
  .section {
    padding: 2rem;
  }
}

@media (min-width: 768px) {
  .section {
    padding: 2.5rem 3rem;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 100%;
  height: 0;
}

.frame {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
}

.frame img {
  height: 100%;
}

.container {
  opacity: 0;
  transform: translateY(60px);
}

.animation {
  animation: fade-in forwards 1s ease-in-out, move-back-y forwards 1s ease-in-out;
}

@media (max-width: 767px) {
  .frame img {
    height: 57%;
  }
  .frame img {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .videoWrapper {
    padding-bottom: 56.25%; /* 16:9 */
  }
}

@media (min-width: 992px) {
  .frame {
    top: -0.75rem;
    left: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
  }
}

/* keyframes */
@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes move-back-y {
  100% {
    transform: translateY(0px);
  }
}

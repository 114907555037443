.langBtn {
  padding: 0 !important /* overriding material-ui style */;
  border: 1px solid #ffffff !important /* overriding material-ui style */;
  border-radius: 50%;
}
.langBtn:first-child {
  margin-right: 0.5rem;
}
.inactive {
  opacity: 0.4;
}
.inactive:hover,
.active {
  opacity: 1;
}

.main {
  position: relative;
  width: 100%;
  padding: 1rem;
  background: none;
  background-image: url('../../../images/svg/sheet-large.svg');
  background-size: 100% 100%;
  border: none;
  display: flex;
  flex-direction: row;
  text-decoration: none !important; /* in case of link */
}

.image {
  filter: invert(44%) sepia(91%) saturate(530%) hue-rotate(335deg) brightness(94%) contrast(96%);
  height: 4rem;
  width: 4rem;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.5rem;
}

.textContainer {
  width: 100%;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  padding-right: 0.5rem;
}

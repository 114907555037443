.container {
  position: relative;
  opacity: 0;
  animation: fade-in forwards 1s 2s ease-in-out;
}

.gridContainer {
  max-width: 38rem;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .container {
    margin-top: -7rem;
  }
}

@media (min-width: 1200px) {
  .container {
    margin-top: -10rem;
  }
}

/* keyframes */
@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

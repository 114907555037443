.container {
    position: fixed;
    z-index: 1;
    width: 100%;
    pointer-events: none;
  }
  
  .main {
    position: relative;
    display: flex;
    justify-content: flex-end!important;
  }
  
  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    padding: 0 6px;
    border: none;
    border-radius: 50%;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.37);
    background-color: var(--color-secondary);
    pointer-events: all;
  }
  
  .image {
    width: 25px;
    height: 25px;
    fill: white;
  }
  
  .badge {
    position: absolute;
    top: -0.1rem;
    right: 0.15rem;
    color: white;
    font-size: 0.8rem;
    font-weight: 800;
    text-shadow: 1px -1px 3px gray;
  }
  
  @media (min-width: 768px) {
    .container {
      bottom: auto;
      right: -3rem;
    }
  }
  
  @media (max-width: 767px) {
    .container {
      bottom: 4.5rem;
      right: 0;
    }
    
    .main {
      max-width: none;
      padding-right: 1.25rem;
    }
  }
  
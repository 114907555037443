
.runningLine {
    background-color: #EB672C;
    height: 45px;
    display: flex;
    align-items: center;
    margin: 0 0 22px 0;
}

.homepageLine {
    background-color: #EB672C;
    height: 45px;
    display: flex;
    align-items: center;
}

.runningList {
    display: flex;
    align-items: center;
    -moz-column-gap: 30px;
    column-gap: 30px;
    margin: 0;
}

.runningItem {
    list-style: none;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.runningItem > img {
    margin-right: 30px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

.post {
  opacity: 0;
  transform: translateX(-1000px);
}

.postAnimation {
  animation: fade-in 1s forwards ease-in-out, move-in 1s forwards ease-in-out;
}

.title {
  opacity: 0;
}

.titleAnimation {
  animation: fade-in 1s forwards ease-in-out;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* keyframes */
@keyframes move-in {
  100% {
    transform: translateX(0);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

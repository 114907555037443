.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}
@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 22rem;
  margin: 0 auto;
  background-image: url('./sheet.svg');
  background-size: 100% 100%;
}

.imageBlock {
  position: relative;
  height: 0;
  padding-bottom: 75%;
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem 0;
  object-fit: cover;
}

.name {
  position: absolute;
  bottom: -1.25rem;
  left: 0.75rem;
  padding: 0.5rem;
  background-image: url('../../../images/svg/bar.svg');
  background-size: 102% 110%;
  background-position: left center;
  font-size: 1.25rem;
  font-weight: 800;
  text-transform: uppercase;
}

.textBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  padding-right: 0;
  color: white;
  line-height: 1.2;
}

.title {
  margin-bottom: 1rem;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
}

.text {
  margin-bottom: 1.5rem;
  padding-right: 3rem;
}

.link {
  color: var(--color-secondary) !important;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: underline;
}

.container {
  max-width: 38rem;
  margin: 0 auto;
}

.section {
  padding: 2rem 1.5rem;
  background-image: url('../../../images/svg/sheet.svg');
  background-size: 100% 100%;
}

@media (min-width: 576px) {
  .section {
    padding: 2rem;
  }
}

@media (min-width: 768px) {
  .section {
    padding: 2.5rem 3rem;
  }
}

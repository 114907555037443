.main {
  position: relative;
  margin-top: 80px;
}

.underlay {
  position: absolute;
  top: 5%;
  right: -50%;
  right: 0;
  z-index: -1;
  height: 70%;
  overflow: hidden;
  opacity: 0;
  transition: translateX(-80px);
}

.underlayAnimation {
  animation: fade-in forwards 1s 1s ease-in-out, move-back-x forwards 1s 1s ease-in-out;
}

.title {
  opacity: 0;
  transform: translateY(-60px);
}

.titleAnimation {
  animation: slide-in-and-stay forwards 1s 0.5s ease-in-out;
}

.body {
  opacity: 0;
  transform: translateY(-60px);
}

.bodyAnimation {
  animation: slide-in-and-stay forwards 1s 1s ease-in-out;
}

.header {
  opacity: 0;
  transform: translateY(60px);
}

.headerAnimation {
  animation: fade-in forwards 1s 0.5s ease-in-out, move-back-y forwards 1s 0.5s ease-in-out;
}

.tryButton {
  transform: scale(0.7);
  opacity: 0;
}

.tryButtonAnimation {
  animation: fade-in forwards 1s 0.5s ease-in-out, scale-up forwards 1s 0.5s ease-in-out;
}

.loyaltyClub {
  opacity: 0;
  position: relative;
}

.loyaltyClubAnimation {
  animation: fade-in forwards 1s 0.5s ease-in-out;
}

.leftArrow {
  position: absolute;
  left: -30%;
  top: 3%;
  transform: rotate(180deg);
}

.rightArrow {
  position: absolute;
  right: -30%;
  top: 3%;
  transform: rotate(180deg);
}

@media (min-width: 360px) {
  .underlay {
    right: -80%;
  }
}
@media (min-width: 400px) {
  .underlay {
    right: -65%;
  }
}
@media (min-width: 460px) {
  .underlay {
    right: -55%;
  }
}
@media (min-width: 500px) {
  .underlay {
    right: -45%;
  }
}
@media (min-width: 576px) {
  .underlay {
    right: -40%;
    height: 80%;
  }
}
@media (min-width: 768px) {
  .underlay {
    top: 5%;
    right: -30%;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .underlay {
    right: 10%;
  }
}
@media (min-width: 1200px) {
  .underlay {
    top: 0;
  }
}

.arrow {
  opacity: 0;
  transform: translateY(-30px);
  width: 3rem;
}

.arrowAnimation {
  animation: fade-in forwards 1s 0.5s ease-in-out, move-back-y forwards 1s 0.5s ease-in-out;
}

.repairRebel {
  display: inline-block;
  padding: 0.5rem 0.75rem 0.75rem;
  background-image: url('../../../images/svg/bar.svg');
  background-size: 100% 100%;
}

.frame {
  text-align: center;
  padding: 3rem 2rem;
  background-image: url('../../../images/svg/background-small.svg');
  background-size: 100% 100%;
  color: white;
}

.superRebel {
  display: inline-block;
  margin-bottom: -0.25rem;
  padding: 0.25rem 0.5rem 0.5rem;
  background-image: url('../../../images/svg/bar-secondary.svg');
  background-size: 100% 100%;
}

.frameParagraph {
  width: 39rem;
  margin: 0 auto 1.5rem;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.button {
  background-color: transparent !important;
  background-image: url('../../../images/png/button.png') !important;
  background-size: 100% 100%;
  mask: none;
}

@media (min-width: 768px) {
  .frame {
    padding: 4rem;
    background-image: url('../../../images/svg/background-large.svg');
  }
}

@media (min-width: 1200px) {
  .frame {
    padding: 7rem;
  }
  .frameContent {
    transform: scale(1.2);
  }
}

@media (max-width: 1050px) {
  
  .leftArrow {
    width: 190px;
    height: 20px;
    top: 13%;
    left: -10%;
  }

  .rightArrow {
    width: 190px;
    height: 20px;
    top: 13%;
    right: -10%;
  }

  .frameParagraph {
    font-size: 18px;
    width: 15rem;
  }
}

/* keyframes */

@keyframes slide-in-and-stay {
  100% {
    opacity: 1;
    transform: translateY(30px);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes move-back-y {
  100% {
    transform: translateY(0);
  }
}

@keyframes scale-up {
  100% {
    transform: scale(1);
  }
}

@keyframes move-back-x {
  100% {
    transform: translateX(0px);
  }
}

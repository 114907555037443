.main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 20px;
  gap: 30px;
}

.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.contactBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 1rem;
}

.contact {
  display: inline-block;
  margin-top: -0.25rem;
  padding: 0.25rem 0.5rem 0.5rem;
  background-image: url('../../images/svg/bar2.svg');
  background-size: 101% 100%;
  background-position-x: right;
  font-weight: 800;
  color: black;
}

.logosGroup {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.contact:hover {
  color: black;
}

.paypalImageBlock {
  display: flex;
  align-self: flex-start;
}

.socialLink {
  display: inline-block;
}

.socialLink:not(:last-child) {
  margin-right: 0.5rem;
}

.footerColumnTitle {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
}

.footerNavigationLink {
  font-size: 16px;
  color: #ffffff;
  transition: text-decoration 0.3s ease-in-out;
}

.footerNavigationLink:hover {
  color: #ffffff;
  text-decoration: underline;
}


.footerNavigationItem {
  display: flex;
  flex-direction: column;
}

.paypal {
  width: 120px;
  top: 115px;
}

@media (max-width: 768px) {
  .main {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  .footerWrapper {
    flex-direction: column;
  }
  .mobileContactBlock {
    width: 100%;
  }
  .logosGroup {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 30px;
   width: 100%;
  }
}

@media (max-width: 768px) {
  .paypal {
    top: 145px;
  }
}

@media (max-width: 376px) {
  .paypal {
    top: 170px;
  }
}

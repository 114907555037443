.awards {
    margin-bottom: 195px;
    position: relative;
}

.awards-container {
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
}

.swiper {
    height: 120px;
}

.title{
    width: 65%;
}

.arrow {
    width: 100%;
}

.header-awards {
    text-align: center;
    display: flex;
    align-items: center; 
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

@media (max-width: 920px) {
    .awards {
        margin-bottom: 40px;
    }

    .swiper-button-prev {
        left: 20px!important;
    }

    .swiper-button-next {
        right: 20px!important;
    }
}

.swiper-container {
    position: static;
}

.swiper-button-prev {
    left: -45px;
    color: #221F20;
    top: 40%;
    background: url('./slider-arrow-left.svg') no-repeat;
}

.swiper-button-next {
    right: -45px;
    color: #221F20;
    top: 40%;
    background: url('./slider-arrow-right.svg') no-repeat;
}

.swiper-button-next::after {
    content: none;
}

.swiper-button-prev::after {
    content: none;
}



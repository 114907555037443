.registerSection,
.loginSection {
  max-width: 38rem;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  background-size: 100% 100%;
}
.registerSection {
  background-image: url('../../../images/svg/sheet-large.svg');
}
.loginSection {
  background-image: url('../../../images/svg/sheet.svg');
}

.textField {
  margin-bottom: 0.5rem !important; /* overriding defaults */
}

@media (min-width: 576px) {
  .registerSection,
  .loginSection {
    padding: 2rem;
  }
}

@media (min-width: 768px) {
  .registerSection,
  .loginSection {
    padding: 2.5rem 3rem;
  }
}

@media (min-width: 992px) {
  .loginBox {
    padding-top: 4rem !important; /* overriding defaults */
  }
}

.main {
  display: block;
  text-align: center;
  transform: translateY(60px);
  opacity: 0;
}

.mainAnimation {
  animation: fade-in forwards 1s ease-in-out, move-back-y forwards 1s ease-in-out;
}

.imageContainer {
  display: block;
  text-align: center;
}
.image {
  height: 6rem;
}
.title {
  display: block;
}
.titleText {
  padding: 10px;
  background-image: url('../../images/svg/bar2.svg');
  background-repeat: no-repeat;
  background-size: 103% 110%;
  background-position-x: center;
}
.message {
  display: block;
}
.paragraph {
  line-height: 1.2;
}

/* keyframes */
@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

@keyframes move-back-y {
  100% {
    transform: translateY(0px);
  }
}

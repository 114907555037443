.nav {
  position: fixed;
  z-index: 100;
  background: #5bbccc;
  height: 4.5rem;
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.menuButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0 0.5rem 0 0;
  border: none;
  background: none;
  margin-left: -0.25rem;
}

.menuImage {
  width: 1.25rem;
}

.logoImage {
  width: 12.5rem;
}

@media (min-width: 992px) {
  .menuButton {
    display: none;
  }
}

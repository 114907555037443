.container {
  opacity: 0;
}

.containerAnimation {
  animation: fade-in 1s forwards ease-in-out;
}

.list {
  display: flex;
  justify-content: space-evenly;
  padding: 1.5rem;
  background-image: url('./bg.svg');
  background-size: 100% 100%;
}

.feature {
  display: flex;
  align-items: center;
}

.featureImageBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 4rem;
  margin-right: 0.25rem;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 22rem;
  height: 100%;
  margin: 0 auto;
  background-image: url('../../../images/svg/sheet2.svg');
  background-size: 100% 100%;
  color: inherit !important; /* for link pseudo-classes */
  text-decoration: none !important; /* for link pseudo-classes */
}

.imageBlock {
  position: relative;
  padding: 0.5rem 0.5rem 0;
}
.imageWrapper {
  position: relative;
  padding-bottom: 75%;
  display: flex;
  margin-top: 1rem;
}
.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.videoIframe,
.mediaOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title {
  position: absolute;
  bottom: -2.25rem;
  left: 0.5rem;
  max-width: 90%;
  padding: 0.5rem;
  background-image: url('../../../images/svg/bar.svg');
  background-size: 102% 220%;
  background-position: left center;
  font-size: 1.1rem;
  font-weight: 800;
  text-transform: uppercase;
  overflow-wrap: hidden;
  text-overflow: ellipsis;
}

.summaryBlock {
  flex: 1;
  display: flex;
  /* align-items: center; */
  padding: 50px 10px;
  padding-bottom: 30px;
  line-height: 1.2;
  position: relative;
}

.summary {
  flex: 1;
}

.readMoreBlock {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media (max-width: 1999px) {
  .summaryBlock {
    padding-bottom: 35px;
  }
}

.main {
  position: relative;
  width: 100%;
  border: none;
  padding: 0;
  background: none;
  overflow: hidden;
  transition: 0.4s ease-in-out;
}

.contentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 70%;
  padding: 5% 0;
}

.image {
  filter: invert(44%) sepia(91%) saturate(530%) hue-rotate(335deg) brightness(94%) contrast(96%);
  max-height: 100%;
  max-width: 100%;
}

@media (hover: hover) {
  .main:hover {
    transform: scale(1.1);
  }
}

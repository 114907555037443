
.body {
  opacity: 0;
  transform: translateY(-120px);
  flex: 0 1 50%;
}

.bodyImgBlock {
  opacity: 0;
  transform: translateX(120px);
  flex: 0 1 50%;
  background-image: url('../../../images/svg/sheet-large.svg');
  background-size: 100% 100%;
}

.bodyImgBlockLeft {
  opacity: 0;
  transform: translateX(-120px);
  flex: 0 1 50%;
  background-image: url('../../../images/svg/sheet-large.svg');
  background-size: 100% 100%;
}

.bodyAnimation {
  -webkit-animation: slide-in-and-stay forwards 1s 1s ease-in-out;
  animation: slide-in-and-stay forwards 1s 1s ease-in-out;
}

.imgAnimation {
  -webkit-animation: img-animate forwards 0.7s 0.7s ease-in-out;
  animation: img-animate forwards 0.7s 0.75s ease-in-out;
}

.imgAnimationLeft {
  -webkit-animation: img-animate-left forwards 0.7s 0.7s ease-in-out;
  animation: img-animate-left forwards 0.7s 0.7s ease-in-out;
}

/* keyframes */

@-webkit-keyframes slide-in-and-stay {
    100% {
      opacity: 1;
      transform: translateY(0px);
  }
}

@-webkit-keyframes slide-in-and-stay {
    100% {
      opacity: 1;
      transform: translateY(0px);
  }
}

@keyframes slide-in-and-stay {
    100% {
      opacity: 1;
      transform: translateY(0px);
  }
}

@-webkit-keyframes img-animate {
  100% {
    opacity: 1;
    transform: translateX(-0px);
  }
}

@-webkit-keyframes img-animate {
  100% {
    opacity: 1;
    transform: translateX(-0px);
  }
}

@keyframes img-animate {
  100% {
    opacity: 1;
    transform: translateX(-0px);
  }
}

@-webkit-keyframes img-animate-left {
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes img-animate-left {
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes img-animate-left {
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
  
  
  @-webkit-keyframes fade-in {
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fade-in {
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes move-back-y {
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes move-back-y {
    100% {
      transform: translateY(0);
    }
  }
  
  @-webkit-keyframes scale-up {
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes scale-up {
    100% {
      transform: scale(1);
    }
  }
  
  @-webkit-keyframes move-back-x {
    100% {
      transform: translateX(0px);
    }
  }
  
  @keyframes move-back-x {
    100% {
      transform: translateX(0px);
    }
  }
  
  
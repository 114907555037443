.list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
}

@media (max-width: 575px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
  .listItemWithLink {
    grid-column: 1 / 3;
    width: 80%;
    margin: auto;
    /* height: 90%; */
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.header {
  padding: 1rem;
}

.footer {
  text-align: right;
  padding: 1rem;
  font-size: 0.75rem;
  font-weight: 800;
}

.closeButton {
  display: inline-flex;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: none;
  padding: 0;
  background: none;
}

.contact {
  color: black;
}

.contact:hover {
  color: black;
}

@media (max-width: 991px) {
  .footer {
    margin-bottom: 55px;
  }
}

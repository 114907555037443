.swiper {
    margin-bottom: 45px;
}

.slide {
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 560px;
}


  
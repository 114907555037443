.body1 {
  font-size: 1rem;
  line-height: 1.2;
}

.link {
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: underline;
}

@media (min-width: 1200px) {
  .body1 {
    font-size: 1.25rem;
  }
}

/* For textual pages like Terms of Service */

.article h2 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}
.article p {
  text-align: justify;
}
.article a {
  color: white;
}

@media (max-width: 576px) {
  .article ul {
    padding-left: 1.2rem;
  }
}
